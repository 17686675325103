<template>
  <article-page :menu="menu">
    <div class="training-content" id="multiple-selecting">
      <div class="_subtitle">
        Множественный выбор лотов
      </div>
      <alert-message type="warning" full-info>
        <template #content>
          Множественный выбор лотов доступен на аккаунтах с подключенной опцией "Управление жизненным циклом заявок и контрактов" или при наличие как минимум 1 созданного тега.
          В ином случае, работать с лотами в поиске можно только по одному.
        </template>
      </alert-message>
      <span>
        В поиске предусмотрен множественный выбор лотов для быстрого назначения им статуса/тега.
        Для выбора тендера, кликните на чекбокс или в любое пустое место внутри карточки тендера (все, кроме ссылок, кнопок и полей для ввода данных).
      </span>
      <span>
        Выбранный лот отличается установленным флажком (чекбоксом), а так же цветом фона - он становится зеленым.
      </span>
      <article-image :src="images[0].src" :alt="images[0].alt" width="700px" @click="openViewer(images, 0)"/>
      <span>
        При выборе как минимум 1 лота, становится активной панель действий:
      </span>
      <article-image :src="images[1].src" :alt="images[1].alt" width="700px" @click="openViewer(images, 1)"/>
      <span>
        Панель состоит из следующих элементов:
      </span>
      <ul class="list">
        <li>
          Чекбокс <b>"Выбрать все"</b> - позволяет в один клик выбрать весь результат поиска
          (на данный момент имеет ограничение в 40 лотов, поэтому если количество результатов становится больше 40, чекбокс становится неактивным)
        </li>
        <li>
          Элемент <b>"Назначить статус"</b> - данный элемент представляет собой видоизмененный селектор статусов, похожий на селектор в карточке лота.
          Он имеет два быстрых статуса (они вынесены в отдельные иконки - статусы "расчет заявки" и "отказ от закупки"), а так же сам селектор.
          При клике на иконку <ui-icon :icon="UiIconNames.Chevron_ArrowDown" :size="14" /> появляется окошко со всеми статусами. При клике на нужный статус,
          он применится на все выбранные лоты.
        </li>
        <li>
          Элемент <b>"Назначить тег"</b> - он появляется, если в системе есть созданные теги. Как создать и работать с тегами, можно прочитать
          <accessed-link :name="Tabs.Training.Tags" target="_blank">здесь</accessed-link>. При клике на тег, он применится ко всем выбранным лотам.
        </li>
        <li>
          Количество результатов и кнопка <b>"Снять выбор лотов"</b> - последний элемент в панели. В нем выводится количество выбранных лотов, а так же кнопка
          <ui-icon :icon="UiIconNames.Icon_CircleClose" :size="14" />, которая очистит весь список выбранных лотов.
        </li>
      </ul>
      <span>
        Прежде, чем применить внесенные изменения, система попросит Вас подтвердить совершаемое действие.
        Диалог так же содержит чекбокс "Снять выбор лотов после внесения изменений". По умолчанию, при открытии окна он будет всегда положителен.
        Он означает, что после успешного применения статуса или тега, количество выбранных лотов станет равно нулю. Если Вы хотите после применяемого действия совершить еще одно над уже выбранными лотами,
        снимите галочку с чекбокса.
      </span>
      <article-image :src="images[2].src" :alt="images[2].alt" width="480px" @click="openViewer(images, 2)"/>
    </div>
  </article-page>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { useImageViewer } from "@/use/other/useImageViewer";
import { useTagsEditor } from "~/globals/tags/useTagsEditor";

import ArticleImage from "~/components/pages/training/ArticleImage.vue";
import ArticlePage from "~/components/pages/training/ArticlePage.vue";
import AlertMessage from "~/components/ui/alert/AlertMessage.vue";
import AccessedLink from "~/components/ui/links/AccessedLink.vue";
import UiIcon from "~/components/ui/icon/UiIcon.vue";
import { Tabs } from "@/router/tabs";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "MultiCardActionsArticle",
  components: {
    UiIcon,
    AccessedLink,
    AlertMessage,
    ArticlePage,
    ArticleImage,
  },
  setup() {

    const menu = [
      { id: 'multiple-selecting', title: 'Множественный выбор лотов' },
    ]

    const images = [
      { src: 'search/selected-lot.png', alt: 'Выбранный лот' },
      { src: 'search/action-panel.png', alt: 'Панель действий' },
      { src: 'search/confirm-dialog.png', alt: 'Модальное окно подтверждения совершаемого действия' },
    ]

    const { openViewer } = useImageViewer();
    const { openTagEditor } = useTagsEditor();

    return {
      menu,
      images,
      openViewer,
      openTagEditor,
      Tabs,
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/pages/training';

.list {
  li {
    margin-bottom: 12px;
  }
}
</style>
